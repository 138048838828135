<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline date-picker date-picker-small mr-8">
                    <el-date-picker
                        v-model="dateRange"
                        type="monthrange"
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份"
                        value-format="yyyy-MM"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="filter">
                <span style="float:left;margin-right:4px;">字段：</span>
                <el-checkbox-group v-model="selectedFieldList">
                    <el-checkbox v-for="item in fieldList" :key="item.name" :label="item.name">{{ item.text }}</el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="filter">
                筛选：
                <div class="inline mr-8">
                    <el-select
                        v-model="filters.projectId"
                        filterable
                        clearable
                        placeholder="项目"
                        size="small"
                        @change="projectIdChangeHandle"
                        style="width: 300px;">
                        <el-option
                                v-for="item in projectList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-select
                        v-model="filters.supplierId"
                        filterable
                        clearable
                        placeholder="分包单位"
                        size="small"
                        style="width: 300px;">
                        <el-option
                                v-for="item in supplierList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <template v-for="item in fieldList">
                            <el-table-column
                                :key="item.name"
                                v-if="selectedFieldList.includes(item.name)"
                                :prop="item.name"
                                :label="item.text"
                                :width="item.width">
                            </el-table-column>
                        </template>
                        <el-table-column
                            prop="payin1"
                            label="押金充值">
                        </el-table-column>
                        <el-table-column
                            prop="payin0"
                            label="租金充值">
                        </el-table-column>
                        <el-table-column
                            prop="fine"
                            label="罚款金额">
                        </el-table-column>
                        <el-table-column
                            prop="escalation"
                            label="调差金额">
                        </el-table-column>
                        <el-table-column
                            prop="paidMoney"
                            label="已付金额">
                        </el-table-column>
                        <el-table-column
                            prop="unPaidMoney"
                            label="未付金额">
                        </el-table-column>
                        <el-table-column
                            prop="payout1"
                            label="押金退费">
                        </el-table-column>
                        <el-table-column
                            prop="payout0"
                            label="租金退费">
                        </el-table-column>
                        <!-- <el-table-column
                            prop="rent"
                            align="center"
                            label="租金">
                        </el-table-column>
                        <el-table-column
                            prop="fine"
                            align="center"
                            label="罚金">
                        </el-table-column>
                        <el-table-column
                            prop="escalation"
                            align="center"
                            label="调差">
                        </el-table-column>
                        <el-table-column
                            prop="paidMoney"
                            align="center"
                            label="已付款">
                        </el-table-column>
                        <el-table-column
                            prop="rechargeAmount"
                            align="center"
                            label="累计充值金额">
                        </el-table-column>
                        <el-table-column
                            prop="refund"
                            align="center"
                            label="退款金额">
                        </el-table-column>
                        <el-table-column
                            prop="roomCount"
                            align="center"
                            label="房间数量">
                        </el-table-column> -->
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[10, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'

export default {
    name: 'CostReport',
    components: {

    },
    data() {
        return {
            dateRange: [],
            filters: {
                supplierId: '',
                projectId: '',
            },
            fieldList: [
                { name: "year", text:"年", width: "80", },
                { name: "month", text:"月", width: "80" },
                { name: "projectName", text:"项目名称", width: "auto" },
                { name: "supplierName", text:"分包单位", width: "auto" },
            ],
            selectedFieldList: ["year","month","projectName",],
            projectList: [],
            supplierList: [],
            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 10,

            curPageNum: 1,

            tableLoading: false,

            detailDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadPojectList();
        this.loadTable();
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list", { params: { projectId: this.filters.projectId } }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadPojectList() {
            http.get("v1/org/projectList").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.projectList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        projectIdChangeHandle() {
            this.filters.supplierId = null;
            if (this.filters.projectId) {
                this.loadSupperList();
            }
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            if (this.dateRange && this.dateRange.length >= 2) {
                params.startMonth = this.dateRange[0];
                params.endMonth = this.dateRange[1];
            }

            params.fieldList = this.selectedFieldList.join(',');

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/report/page', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.data.records;
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },
        handleExport() {}
    }
}
</script>

<style scoped>
</style>
